<template id="faq-view">
  <form
    ref="form"
    @submit.prevent="handleOk"
  >
    <b-row>
      <b-col
        v-if="id == null"
        md="10"
      >
        <h5>
          {{ $t('Faq') }}
        </h5>
      </b-col>
      <b-col style="align-self: center; text-align: end;">
        <b-button
          variant="success"
          @click="handleOk"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </b-col>
    </b-row>
    <validation-observer
      ref="faq"
      tag="form"
    >
      <b-row>
        <b-col md="9">
          <validation-provider
            v-slot="{ errors }"
            name="question"
            :rules="language == base ? 'required' : ''"
          >
            <b-form-group
              :label="$t('resources.question') + ' *'"
              label-for="question"
              :invalid-feedback="$t('required')"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="question"
                v-model="question"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('resources.questionPlace')"
                maxlength="300"
                required
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="language == base"
          style="align-self: center;"
          md="3"
        >
          <b-form-checkbox
            v-model="isActive"
            switch
          >
            {{ $t('dataGeneric.activeQ') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="answer"
            :rules="language == base ? 'required' : ''"
          >
            <b-form-group
              :label="$t('testDetailed.answer') + ' *'"
              label-for="answer"
              :invalid-feedback="$t('required')"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="answer"
                v-model="answer"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('resources.answerPlace')"
                required
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </form>
</template>
<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BFormInput,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { isEmpty, messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    id: { default: null },
    order: {
      type: Number,
      default: 0,
    },
    base: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    createBool: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      required,
      userData: getUserData(),
      answerState: null,
      questionState: null,
      isActive: true,
      question: null,
      answer: null,
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.id) this.getFaq()
  },
  methods: {
    getFaq() {
      const { headers } = this
      axios
        .post('', {
          query: `
          {
              faq(id:"${this.id}")
              {              
                id
                question(lang:"${this.language}")
                answer(lang:"${this.language}")
                isActive
              }
          }
          `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.question = res.data.data.faq.question
          this.answer = res.data.data.faq.answer
          this.isActive = res.data.data.allBadge.isActive
          this.show = false
        })
        .catch(() => {
          this.show = false
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageFile':
          this.imageFile = data
          break
        default:
          break
      }
    },
    chooseInput(dato) {
      document.getElementById(dato).click()
    },
    handleOk() {
      return new Promise(resolve => {
        this.$refs.faq.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.id !== null) { this.editFaq() } else this.createFaq()
          }
        })
      })
    },
    createFaq() {
      const { headers } = this
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
            mutation($question: String!,$answer: String!,$order: Int!,$client: ID!,$isActive: Boolean){
              createFaq(input:{
                question:$question,
                answer:$answer,
                order:$order,
                client:$client,
                isActive:$isActive,
                }){
                faq {
                  id
                }
              }
            }
          `
      const variables = {
        question: this.question,
        answer: this.answer,
        order: this.order,
        isActive: this.isActive,
        client: userData.profile.client.id,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })

      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },

    editFaq() {
      const { headers } = this

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let objectVariables = []
      objectVariables = [
        { title: `question${this.language}`, value: this.question, type: 'String' },
        { title: `answer${this.language}`, value: this.answer, type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'isActive', value: this.isActive, type: 'Boolean' },
        )
      }
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateFaq(id:"${this.id}",input:{\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
                faq {
                   id
                 }
                }
            }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.show = false
        })
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      console.log(valid)
      if (isEmpty(this.answer)) this.answerState = valid
      if (isEmpty(this.question)) this.questionState = valid
      return valid
    },

  },
}
</script>

<style lang="scss" scoped>
#faq-view .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#faq-view .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}

#faq-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#faq-view .card-body h4 {
  font-size: 1.286rem !important;
}

#faq-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#faq-view .fly-image-input {
  max-width: 100%;
}

#faq-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#faq-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#faq-view .div {
  display: inline-block;
}

#faq-view .inputColor {
  visibility: hidden;
}

#faq-view .placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#faq-view .placeholder_photo:hover {
  background: #e0e0e0;
}

#faq-view .file-input {
  display: none;
}

#faq-view .cBox {
  align-items: center;
}

#faq-view .custom-control-label {
  font-size: 15px;
}

#faq-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#faq-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#faq-view .contain {
  object-fit: contain;
  cursor: pointer;
}
</style>
