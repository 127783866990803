<template>
  <section id="clasification">
    <b-row class="match-height mt-2">
      <b-col>
        <b-card v-if="clasification != null">
          <b-card-header>
            <b-card-title>{{ $t('epg.ageClasific') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-overlay
                  :show="show"
                  variant="transparent"
                  rounded="sm"
                >
                  <b-list-group
                    v-if="clasification.length != 0"
                    class="list"
                  >
                    <b-list-group-item
                      v-for="i in clasification"
                      :key="i.node.id"
                      class="d-flex justify-content-between"
                    >
                      <div>
                        <small><span
                                 class="mr-50 bullet  bullet-sm"
                                 :style="`background-color: ${i.node.color};`"
                               />
                          {{ i.node.initial }} - {{ i.node.description }}</small>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-jumbotron
                    v-else
                    bg-variant="transparent"
                    border-variant="primary"
                    :header="$t('epg.noExist')"
                    class="text-center"
                  />
                </b-overlay>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BCard,
  BCardHeader,
  BCardBody,
  BJumbotron,
  BListGroupItem,
  BListGroup,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError } from '@/store/functions'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardBody,
    BListGroup,
    BJumbotron,
    BCardTitle,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      clasification: null,
      show: false,
      id: null,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {

    getData() {
      this.show = true
      const userData = getUserData()

      axios
        .post('', {
          query: `                
          {
            client(id: "${userData.profile.client.id}"){         
                  id                
                  clasifications {
                    totalCount
                    edges {
                      node {
                        type
                            initial
                            description
                            color
                            order
                      }
                    }
                  }
                }
                        
        }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.clasification = res.data.data.client.clasifications.edges
          this.clasification = this.clasification.sort(
            (a, b) => a.node.order - b.node.order,
          )
          this.show = false
        })
        .catch(err => {
          console.log(err)
          this.show = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#clasification .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#clasification .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}
</style>
