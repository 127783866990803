import { render, staticRenderFns } from "./SettingsLimitsTable.vue?vue&type=template&id=6886510d&scoped=true"
import script from "./SettingsLimitsTable.vue?vue&type=script&lang=js"
export * from "./SettingsLimitsTable.vue?vue&type=script&lang=js"
import style0 from "./SettingsLimitsTable.vue?vue&type=style&index=0&id=6886510d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6886510d",
  null
  
)

export default component.exports