<template>
  <div>
    <b-tabs
      v-if="languageService"
      pills
      lazy
      vertical
    >
      <b-tab
        v-for="i in locales"
        :key="i.locale"
        class="align-items-start"
        style="align-items: flex-start;"
      >
        <template #title>
          <b-img
            :src="i.img"
            height="14px"
            width="22px"
            :alt="i.locale"
          />
          <strong class="ml-50">{{ i.name }}</strong>
        </template>
        <settings-tabs-legal-texts
          :key="i.locale"
          :language="i.locale"
          :base="base"
        />
      </b-tab>
    </b-tabs>
    <settings-tabs-legal-texts
      v-else
      :language="base"
      :base="base"
    />
  </div>
</template>
<script>
import {
  BTabs,
  BImg,
  BTab,
} from 'bootstrap-vue'
import localesLanguages from '@core/utils/languageModels'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import SettingsTabsLegalTexts from './SettingsTabsLegalTexts.vue'

export default {
  components: {
    BTabs,
    BTab,
    BImg,

    SettingsTabsLegalTexts,

  },
  data() {
    return {
      languages: [],
      tabCounter: 0,
      categoryName: null,
      locales: [],
      languageService: true,
      overlay: true,
      interactivities: false,
      state: null,
      tabIndex: 0,
      name: null,
      base: 'Es',
      viewName: this.$t('contents.editCategoryTitle'),
      viewDetail: this.$t('contents.editCategorySub'),
      userData: getUserData(),

    }
  },
  mounted() {
    this.getClient()
  },
  methods: {
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')

      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-') // Dividir el idioma en partes usando '-'
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)) // Capitalizar cada parte
          .join('') // Unir las partes sin espacio
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    getClient() {
      axios
        .post('', {
          query: `
              query{
              client(id:"${this.userData.profile.client.id}"){
                  externalPayMethod
                  currency
                  defaultLanguage
                  availableLanguages
              }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`

          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))
          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },
  },
}
</script>
