<template>
  <section id="settings">
    <h1>{{ $t("setting.title") }}</h1>
    <h5 class="text-primary">
      {{ $t("setting.subtitle") }}
    </h5>
    <b-tabs
      pills
      lazy
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>{{ $t("settingTabs.general") }}</span>
        </template>
        <settings-tabs-general
          class="mt-2"
          @return="show = !show"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>{{ $t("settingTabs.connect") }}</span>
        </template>
        <settings-tabs-connection class="mt-2" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="BookOpenIcon" />
          <span>{{ $t("settingTabs.GDPR") }}</span>
        </template>
        <settings-tabs-legal-texts class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>{{ $t("settingTabs.gestion") }}</span>
        </template>
        <settings-tabs-user class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>{{ $t("settingTabs.paid") }}</span>
        </template>
        <settings-tabs-paid class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BarChart2Icon" />
          <span>{{ $t("settingTabs.stadistisc") }}</span>
        </template>
        <settings-tabs-statistics class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="MessageSquareIcon" />
          <span>{{ $t("settingTabs.notif") }}</span>
        </template>
        <settings-tabs-notification class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="LinkIcon" />
          <span>{{ $t("settingTabs.domain") }}</span>
        </template>
        <settings-tabs-domain class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="Edit2Icon" />
          <span>SEO</span>
        </template>
        <settings-tabs-seo class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="Edit2Icon" />
          <span>{{ $t('Géneros') }}</span>
        </template>
        <settings-tabs-genre class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t('epg.ageClasific') }}</span>
        </template>
        <settings-tabs-clasification class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Badges</span>
        </template>
        <settings-tabs-badges class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ShoppingCartIcon" />
          <span>{{ $t('Shop') }}</span>
        </template>
        <settings-tabs-shop class="mt-2" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ToolIcon" />
          <span>Brightcove</span>
        </template>
        <settings-tabs-brigthcove class="mt-2" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="HelpCircleIcon" />
          <span>FAQ</span>
        </template>
        <settings-tabs-faq class="mt-2" />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>

import { BTabs, BTab } from 'bootstrap-vue'

import SettingsTabsGeneral from './components/SettingsTabsGeneral.vue'
import SettingsTabsShop from './components/SettingsTabsShop.vue'
import SettingsTabsBrigthcove from './components/SettingsTabsBrigthcove.vue'
import SettingsTabsLegalTexts from './components/LanguagesTabGDPR.vue'
import SettingsTabsConnection from './components/SettingsTabsConnection.vue'
import SettingsTabsSeo from './components/SettingsTabsSeo.vue'
import SettingsTabsGenre from './components/SettingsTabsGenre.vue'
import SettingsTabsClasification from './components/SettingsTabsClasification.vue'
import SettingsTabsUser from './components/SettingsTabsUser.vue'
import SettingsTabsPaid from './components/SettingsTabsPaid.vue'
import SettingsTabsStatistics from './components/SettingsTabsStatistics.vue'
import SettingsTabsNotification from './components/SettingsTabsNotification.vue'
import SettingsTabsDomain from './components/SettingsTabsDomain.vue'
import SettingsTabsBadges from './components/SettingsTabsBadges.vue'
import SettingsTabsFaq from './components/SettingsTabsFaq.vue'

export default {
  components: {
    BTabs,
    BTab,
    SettingsTabsShop,
    SettingsTabsSeo,
    SettingsTabsGenre,
    SettingsTabsBadges,
    SettingsTabsClasification,
    SettingsTabsGeneral,
    SettingsTabsBrigthcove,
    SettingsTabsLegalTexts,
    SettingsTabsConnection,
    SettingsTabsUser,
    SettingsTabsPaid,
    SettingsTabsStatistics,
    SettingsTabsFaq,
    SettingsTabsNotification,
    SettingsTabsDomain,
  },
  data() {
    return {
      show: true,
    }
  },

}
</script>

<style scoped></style>
