<template>
  <section id="table-platform-limits">
    <b-card
      v-if="tableData"
      class="card-settings-limits"
    >
      <b-table
        :items="tableData"
        :fields="fields"
        responsive
        class="mb-0"
      >
        <!-- Limit -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            {{ data.item.name }}
          </div>
        </template>

        <!-- Available -->
        <template #cell(available)="data">
          <div class="d-flex align-items-center">
            {{ data.item.available }}
          </div>
        </template>

        <!-- Used -->
        <template #cell(used)="data">
          <div class="d-flex align-items-center">
            {{ data.item.used }}
          </div>
        </template>

        <!-- sales -->
        <template #cell(progress)="data">
          <div v-if="data.item.progress != '-'">
            <b-progress
              v-if="data.item.progress < 50"
              max="100"
              class="progress-bar-success"
            >
              <b-progress-bar
                :value="data.item.progress"
                :label="Math.round(data.item.progress * 100) / 100 + '%'"
                variant="success"
              />
            </b-progress>
            <b-progress
              v-else-if="data.item.progress >= 50 && data.item.progress <= 75"
              max="100"
              class="progress-bar-warning"
            >
              <b-progress-bar
                :value="data.item.progress"
                :label="Math.round(data.item.progress * 100) / 100 + '%'"
                variant="warning"
              />
            </b-progress>
            <b-progress
              v-else
              max="100"
              class="progress-bar-danger"
            >
              <b-progress-bar
                :value="data.item.progress"
                :label="Math.round(data.item.progress * 100) / 100 + '%'"
                variant="danger"
              />
            </b-progress>
          </div>
          <div v-else>
            <small>-</small>
          </div>
        </template>

        <template #cell(canIncrement)="data">
          <div class="d-flex align-items-center">
            <b-button
              v-if="data.item.canIncrement"
              v-b-modal.modal-center
              block
              variant="primary"
            >
              {{ $t("code.Solicitar") }}
            </b-button>
            <b-button
              v-else
              block
              disabled
            >
              {{ $t("code.Solicitar") }}
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
    <!-- modal vertical center -->
    <b-modal
      id="modal-center"
      centered
      :title="$t('code.sol')"
      ok-only
      :ok-title="$t('code.accept')"
    >
      <b-card-text>
        {{ $t("code.quest") }}
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard,
  BTable,
  BProgress,
  BProgressBar,
  BButton,
  BModal,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BCardText,
    BCard,
    BTable,
    BProgress,
    BProgressBar,
    BButton,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'name', label: this.$t('code.limit') },
        { key: 'available', label: this.$t('code.limit') },
        { key: 'used', label: this.$t('code.Usado') },
        { key: 'progress', label: this.$t('code.Progreso') },
        { key: 'canIncrement', label: this.$t('code.solicitar') },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

#table-platform-limits .card-lastcontents-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

#table-platform-limits .card-lastcontents-table {
  td {
    img {
      margin-right: 2rem;
      width: 8rem;
    }
  }
}

#table-platform-limits .badge {
  padding: 0.9rem 2rem;
  text-align: center;
  border-radius: 0.358rem;
}

#table-platform-limits .card-settings-limits {
  .progress {
    height: 1.3rem;
    font-weight: 700;
  }
}
</style>
