<template>
  <b-overlay
    variant="transparent"
    :show="showLoading"
  >
    <b-tab
      id="ga4"
      title-item-class="w-25"
      lazy
    >
      <template #title>
        <b-card
          class="bg-light"
          :img-src="require('@/assets/images/backend/analytics/ga4.png')"
          overlay
        />
      </template>
      <b-row>
        <b-col
          lg="6"
          class="mt-2"
        >
          <b-form @submit.prevent="updateGa4">
            <!-- form input -->
            <b-form-group
              class="mb-2 mr-1"
              :label="'GA4 Tracking URL'"
            >
              <b-form-input
                id="ga4Url"
                v-model="ga4Url"
                type="text"
                maxlength="250"
                :placeholder="' GA4 Tracking URL'"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              label="Google Tag ID"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  id="ga4TagId"
                  v-model="ga4TagId"
                  maxlength="150"
                  type="text"
                  :placeholder="$t('statistics.apiKey')"
                  autocomplete="new-password"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group>
              <b-row class="align-items-center">
                <b-col md="10">
                  <b-form-group label="Archivo .plist para ios">
                    <b-form-file
                      id="ga4Plist"
                      v-model="ga4Plist"
                      accept=".plist"
                      class="mr-1"
                      :placeholder="filePlist"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <a
                    v-if="filePlist"
                    :href="filePlist"
                    download
                    target="_blank"
                  >
                    GA4 Plist</a>
                </b-col>
              </b-row>
              <b-row class="align-items-center">
                <b-col md="10">
                  <b-form-group label="Archivo .json para android">
                    <b-form-file
                      id="ga4Json"
                      v-model="ga4Json"
                      accept=".json"
                      class="mr-1"
                      :placeholder="fileJson"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <a
                    v-if="fileJson"
                    :href="fileJson"
                    download
                    target="_blank"
                  >
                    GA4 Json</a>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              class="mb-2 mr-1"
              label="¿Está usando Google Analytics?"
            >
              <b-form-checkbox
                id="isGa4Active"
                v-model="isGa4Active"
                switch
              />
            </b-form-group>
            <b-button
              type="submit"
              variant="success"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-tab>
  </b-overlay>
</template>
<script>
import { showToast, messageError } from '@/store/functions'
import {
  BRow,
  BTab,
  BCol,
  BCard,
  BFormFile,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BForm,
    BFormCheckbox,
    BFormFile,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ga4Url: null,
      ga4TagId: null,
      showLoading: false,
      apikeyUser: null,
      ga4Plist: null,
      isGa4Active: false,
      filePlist: null,
      fileJson: null,
      ga4Json: null,
      apikeyId: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  mounted() {
    this.getConfigUserData()
  },
  methods: {
    getConfigUserData() {
      const userData = getUserData()
      const query = `
          query {
            client (id: "${userData.profile.client.id}"){
              ga4TagId
              ga4Url
              ga4Json
              isGa4Active
              ga4Plist
            }
          }
        `
      const variables = {
        id: userData.profile.client.id,
      }
      axios.post('', { query, variables }).then(res => {
        messageError(res, this)

        const dataRes = res.data.data.client
        this.ga4Url = dataRes.ga4Url
        this.ga4TagId = dataRes.ga4TagId
        this.isGa4Active = dataRes.isGa4Active
        this.fileJson = dataRes.ga4Json ? dataRes.ga4Json : null
        this.filePlist = dataRes.ga4Plist ? dataRes.ga4Plist : null
      })
    },
    updateGa4(event) {
      this.showLoading = true
      event.preventDefault()
      const userData = getUserData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
          mutation($id: ID!, $ga4Url: String, $ga4TagId: String){
            updateClient(id: $id, input: {
              ga4Url: $ga4Url,
              isGa4Active:${this.isGa4Active},
              ga4TagId: $ga4TagId,
            }){
              client {
                id
                ga4Url
                statisticsApiKey
              }
            }
          }
        `
      const variables = {
        id: userData.profile.client.id,
        ga4Url: this.ga4Url,
        ga4TagId: this.ga4TagId,

      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      if (this.ga4Json != null) data.append('ga4_json', this.ga4Json)
      if (this.ga4Plist != null) data.append('ga4_plist', this.ga4Plist)
      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          this.showLoading = false

          showToast(this.$t('code.modConf'), 1, this)
        })
        .catch(() => {
          this.showLoading = false

          showToast(this.$t('code.errorConf'), 2, this)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#config-platform-stats .card-body {
  padding: 1.3rem;
}
</style>
