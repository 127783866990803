<template>
  <section id="settingsSeo">
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showSeo"
        >
          <b-card>
            <b-card-header>
              <b-card-title>SEO</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form @submit.prevent="updateSeo">
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('title')"
                    >
                      <b-form-input
                        v-model="seoTitle"
                        :placeholder="$t('title')"
                        maxlength="80"
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('dataGeneric.description')"
                    >
                      <b-form-input
                        v-model="seoDescription"
                        :placeholder="$t('dataGeneric.description')"
                        class="mr-1"
                        maxlength="180"
                      />
                    </b-form-group>
                    <image-element-vue
                      :image-origin="imageSeoSelected"
                      :media-origin-selected="'BUK'"
                      :image-type="'imageSeo'"
                      :label="$t('imageShare')"
                      :can-delete="imageSeoSelected?true:false"
                      @deleteImage="deleteImage"
                      @saveImage="loadImage"
                    />
                    <b-button
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormInput,
  BOverlay,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { showToast, messageError } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ImageElementVue from '@/views/components/ImageElement.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BForm,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormInput,
    BOverlay,
    ImageElementVue,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSeo: false,
      seoTitle: null,
      seoDescription: null,
      imageSeo: false,
      imageSeoSelected: null,
      imageClicked: null,
      deleteFile: [],
    }
  },

  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  seoTitle
                  seoDescription
                  mainImage
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.seoTitle = response.node.seoTitle
          this.seoDescription = response.node.seoDescription
          this.imageSeoSelected = response.node.mainImage === '' ? null : response.node.mainImage
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateSeo(event) {
      this.showData = true
      event.preventDefault()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()

      const query = `
            mutation{
            updateClient(id:"${this.id}",input:{
              ${this.seoTitle == null ? '' : `seoTitle:"${this.seoTitle}",`}
              ${
  this.seoDescription == null
    ? ''
    : `seoDescription:"${this.seoDescription}",`
}

            }) {
              client{
                name
                id
              }
            }
          }
        `
      data.append('query', query)
      data.append('main_image', this.imageSeoSelected)

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        // cancelButtonText: "Indicar url",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.showFiles = true

            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
                  mutation {
                    updateClient(id: "${this.id}", input: 
                    {
                      ${this.seoTitle == null ? '' : `seoTitle:"${this.seoTitle}",`}
                        ${
  this.seoDescription == null
    ? ''
    : `seoDescription:"${this.seoDescription}",`
}
                    }) 
                    {
                      client{
                          name
                          id
                        }
                    }
                  }
                `
            data.append('query', query)

            switch (type) {
              case 'imageSeo':
                this.imageSeoSelected = null
                data.append('deleteFile', 'main_image')
                break
              default:
                break
            }

            axios
              .post('', data, config)
              .then(createResponse => {
                messageError(createResponse, this)

                showToast(this.$t('success'), 1, this)
                this.showFiles = false
                this.getClient()
              })
              .catch(res => {
                showToast(this.$t('error'), 0, this)
                this.showFiles = false
                console.log(res)
              })
          }
        })
        .catch(() => { })
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageSeo':
          this.imageSeoSelected = data
          break

        default:
          break
      }
    },
    processImageData(data) {
      switch (this.imageClicked) {
        case 'fileInputImage':
          this.imageSeoSelected = data
          this.imageClicked = ''
          break

        default:
          break
      }
      this.hideModal()
    },

    chooseImage(imageClicked) {
      this.imageClicked = imageClicked

      document.getElementById(imageClicked).click()
    },
  },
}
</script>
