<template>
  <b-overlay
    variant="transparent"
    :show="showLoading"
  >
    <b-tab
      id="countly"
      title-item-class="w-25"
      lazy
    >
      <template #title>
        <b-card
          class="bg-light"
          :img-src="require('@/assets/images/backend/analytics/adobe.png')"
          overlay
        />
      </template>
      <b-row>
        <b-col
          lg="6"
          class="mt-2"
        >
          <b-form @submit.prevent="updateAdobe">
            <!-- form input -->
            <b-form-group
              class="mb-2 mr-1"
              :label="$t('statistics.url')"
            >
              <b-form-input
                id="adobeUrl"
                v-model="adobeUrl"
                type="text"
                :placeholder="$t('statistics.url')"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              label="ID adobe"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  id="adobeEnvId"
                  v-model="adobeEnvId"
                  type="text"
                  :placeholder="$t('statistics.apiKey')"
                  autocomplete="new-password"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              class="mb-2 mr-1"
              label="¿Está usando Adobe?"
            >
              <b-form-checkbox
                id="isAdobeActive"
                v-model="isAdobeActive"
                switch
              />
            </b-form-group>

            <b-button
              type="submit"
              variant="success"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-tab>
  </b-overlay>
</template>
<script>
import { showToast, messageError } from '@/store/functions'
import {
  BRow,
  BTab,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      adobeUrl: null,
      adobeEnvId: null,
      showLoading: false,
      isAdobeActive: false,
      apikeyUser: null,
      apikeyId: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  mounted() {
    this.getConfigUserData()
  },
  methods: {
    getConfigUserData() {
      const userData = getUserData()
      const query = `
          query {
            client (id: "${userData.profile.client.id}"){
              adobeUrl
              isAdobeActive

              adobeEnvId
            }
          }
        `
      const variables = {
        id: userData.profile.client.id,
      }
      axios.post('', { query, variables }).then(res => {
        messageError(res, this)

        const dataRes = res.data.data.client
        this.isAdobeActive = dataRes.isAdobeActive

        this.adobeUrl = dataRes.adobeUrl
        this.adobeEnvId = dataRes.adobeEnvId
      })
    },
    updateAdobe(event) {
      this.showLoading = true
      event.preventDefault()
      const userData = getUserData()
      const query = `
          mutation($id: ID!, $adobeUrl: String, $adobeEnvId: String){
            updateClient(id: $id, input: {
              adobeUrl: $adobeUrl,
              adobeEnvId: $adobeEnvId,
              isAdobeActive:${this.isAdobeActive},

             
            }){
              client {
                id
                statisticsUrl
                statisticsApiKey
              }
            }
          }
        `
      const variables = {
        id: userData.profile.client.id,
        adobeUrl: this.adobeUrl,
        adobeEnvId: this.adobeEnvId,

      }
      axios
        .post('', { query, variables })
        .then(res => {
          messageError(res, this)

          this.showLoading = false

          showToast(this.$t('code.modConf'), 1, this)
        })
        .catch(() => {
          this.showLoading = false

          showToast(this.$t('code.errorConf'), 2, this)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#config-platform-stats .card-body {
  padding: 1.3rem;
}
</style>
