<template>
  <div id="badges">
    <b-row>
      <b-col>
        <b-card v-if="badges != null">
          <b-card-header>
            <b-card-title>Badges</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row
              v-if="badges.length != 0"
              class="mb-1"
            >
              <b-col>
                <h5 class="text-primary">
                  {{ $t('Badges creados') }}
                </h5>
              </b-col>
              <b-col style="text-align-last: end;">
                <b-button
                  variant="primary"
                  @click="actionsBadges('create')"
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    size="14"
                  /> {{ $t('dataGeneric.create') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-overlay
                  :show="show"
                  variant="transparent"
                  rounded="sm"
                >
                  <b-list-group
                    v-if="badges.length != 0"
                    class="list"
                  >
                    <b-list-group-item
                      v-for="i in badges"
                      :key="i.node.id"
                      class="d-flex justify-content-between"
                    >
                      <div>
                        <h4>
                          <span
                            v-if="i.node.isActive"
                            class="mr-50 bullet bullet-success bullet-sm"
                          /><span
                            v-else
                            class="mr-50 bullet bullet-danger bullet-sm"
                          />
                          <b-badge
                            :style="'background-color:' + i.node.backgroundColor + '; color:' + i.node.textColor"
                          >
                            {{
                              i.node.name }}
                          </b-badge>
                        </h4>
                      </div>
                      <div>
                        <span
                          class="mr-1 edit"
                          @click="
                            actionsBadges(
                              'update',
                              i.node.id,
                            )
                          "
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="16"
                          />
                        </span>
                        <span
                          class="trash"
                          @click="deleteBadge(i.node.id)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            size="18"
                          />
                        </span>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-jumbotron
                    v-else
                    bg-variant="transparent"
                    border-variant="primary"
                    :header="$t('noBadgeCreated')"
                    class="text-center"
                  >
                    <p class="text-primary">
                      {{ $t('createBadge') }}
                    </p>

                    <b-col
                      class="text-center m-10 p-5"
                      align-self="center"
                    >
                      <b-button
                        variant="success"
                        @click="actionsBadges('create')"
                      >
                        <span>{{ $t('crtBadg') }}</span>
                      </b-button>
                    </b-col>
                  </b-jumbotron>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="badge-view"
      ref="badge-view"
      size="lg"
      hide-footer
      title="Badge"
      @hidden="resetModal"
    >
      <div class="d-block">
        <b-tabs
          v-if="languageService && !createBool"
          pills
          lazy
          vertical
        >
          <b-tab
            v-for="i in locales"
            :key="i.locale"
          >
            <template #title>
              <div
                class="d-flex"
                style="flex:auto"
              >
                <b-img
                  :src="i.img"
                  height="14px"
                  width="22px"
                  :alt="i.locale"
                />
                <strong class="ml-50">{{ i.name }}</strong>
              </div>
            </template>
            <badges-view
              :id="id"
              :key="i.locale"
              :create-bool="createBool"
              :action="action"
              :language="i.locale"
              :base="base"
              @refresh="getData()"
            />
          </b-tab>
        </b-tabs>
        <badges-view
          v-else
          :id="id"
          :action="action"
          :language="base"
          :base="base"
          :create-bool="createBool"
          @refresh="getData()"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="resetModal()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BButton,
  BCardTitle,
  BCard,
  BTab, BTabs,
  BImg,
  BBadge,
  BModal,
  BCardHeader,
  BCardBody,
  BJumbotron,
  BListGroupItem,
  BListGroup,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import localesLanguages from '@core/utils/languageModels'
import BadgesView from './BadgesView.vue'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardBody,
    BListGroup,
    BJumbotron,
    BBadge,
    BCardTitle,
    BadgesView,
    BListGroupItem,
    BTab,
    BTabs,
    BRow,
    BCol,
    BModal,
    BButton,
    BCard,
    BImg,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      badges: null,
      show: false,
      languages: [],
      languageService: true,
      userData: getUserData(),
      action: null,
      id: null,
      locales: [],
      createBool: false,
      deleteFile: [],
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      console.log(error)
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getClient()
    this.getData()
  },
  methods: {
    errorImg(e) {
      e.target.src = fileError
    },
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')

      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-') // Dividir el idioma en partes usando '-'
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)) // Capitalizar cada parte
          .join('') // Unir las partes sin espacio
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    getClient() {
      axios
        .post('', {
          query: `
              query{
              client(id:"${this.userData.profile.client.id}"){
                 
                  defaultLanguage
                  availableLanguages
              }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`
          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))

          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },
    resetModal() {
      this.show = false

      this.$nextTick(() => {
        this.$bvModal.hide('badge-view')
      })
    },

    getData() {
      this.show = true
      const userData = getUserData()
      const { headers } = this
      axios
        .post('', {
          query: `
                    {
                        allBadge(client:"${userData.profile.client.id}")
                        {
                        edges
                        {
                            node
                            {
                                    id
                                    name
                                    isActive
                                    backgroundColor
                                    textColor
                                    image
                                }
                            }
                        }
                    }
                    `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.badges = res.data.data.allBadge.edges
          this.$nextTick(() => {
            this.$bvModal.hide('badge-view')
          })
          this.show = false
        })
        .catch(() => {
          this.show = false
        })
    },

    deleteBadge(id) {
      this.show = true

      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('infoBad'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
                            mutation{
                                deleteBadge(id:"${id}"){
                                    found
                                    deletedId
                                }
                            }
                            `,
            })
            .then(res => {
              messageError(res, this)

              showToast(this.$t('code.updateData'), 1, this)
              this.getData()
            })
            .catch(() => {
              showToast(this.$t('code.updateDataError'), 2, this)
              this.show = false
            })
        } else {
          this.show = false
        }
      })
    },

    actionsBadges(value, id = null) {
      this.show = true
      switch (value) {
        case 'update':
          this.createBool = false

          this.id = id

          this.$refs['badge-view'].show()
          break
        case 'create':
          this.createBool = true
          this.id = id

          this.$refs['badge-view'].show()
          break

        default:
          break
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.edit:hover {
    color: rgb(0, 83, 7);
    cursor: pointer;
}

.trash:hover {
    color: rgb(109, 0, 0);
    cursor: pointer;
}

.card-body {
    padding: 1rem;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 1px;
}

.card-body h4 {
    font-size: 1.286rem !important;
}

.col-form-label {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.fly-image-input {
    max-width: 100%;
}

.base-color-input {
    display: block;
    border-radius: 15px;
    border: solid 1px;
    border-color: white;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.base-card-input {
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
}

.div {
    display: inline-block;
}

.inputColor {
    visibility: hidden;
}

/*

.inputColor {
    width: auto;
    color: white;
    background-size: cover;
    background-position: center center;
} */

.placeholder_photo {
    background: #f0f0f0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #333;
    font-size: 18px;
    font-family: Helvetica;
}

.placeholder_photo:hover {
    background: #e0e0e0;
}

.file-input {
    display: none;
}

.cBox {
    align-items: center;
}

.custom-control-label {
    font-size: 15px;
}

.scroll {
    overflow: hidden;
    flex-wrap: nowrap !important;

}

.list {
    overflow: hidden;
    overflow-y: scroll;
    height: 380px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: none;
}

.contain {
    object-fit: contain;
    cursor: pointer;
}
</style>
