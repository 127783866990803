<template>
  <div id="genre">
    <b-row>
      <b-col>
        <b-card v-if="genre != null">
          <b-card-header>
            <b-card-title>{{ $t('Géneros') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row
              v-if="genre.length != 0"
              class="mb-1"
            >
              <b-col>
                <h5 class="text-primary">
                  {{ $t('genrCreados') }}
                </h5>
              </b-col>
              <b-col style="text-align-last: end;">
                <b-button
                  variant="primary"
                  @click="actionsGenre('create')"
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    size="14"
                  /> {{ $t('dataGeneric.create') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-overlay
                  :show="show"
                  variant="transparent"
                  rounded="sm"
                >
                  <b-list-group
                    v-if="genre.length != 0"
                    class="list"
                  >
                    <b-list-group-item
                      v-for="i in genre"
                      :key="i.node.id"
                      class="d-flex justify-content-between"
                    >
                      <div>
                        <small><span
                          v-if="i.node.isActive"
                          class="mr-50 bullet bullet-success bullet-sm"
                        /><span
                          v-else
                          class="mr-50 bullet bullet-danger bullet-sm"
                        />{{ i.node.name }}</small>
                      </div>
                      <div>
                        <span
                          class="mr-1 edit"
                          @click="
                            actionsGenre(
                              'update',
                              i.node.id,
                            )
                          "
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="16"
                          />
                        </span>
                        <span
                          class="trash"
                          @click="deleteGenre(i.node.id)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            size="18"
                          />
                        </span>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-jumbotron
                    v-else
                    bg-variant="transparent"
                    border-variant="primary"
                    :header="$t('noExistGenre')"
                    class="text-center"
                  >
                    <p class="text-primary">
                      {{ $t('creatGenre') }}
                    </p>

                    <b-col
                      class="text-center m-10 p-5"
                      align-self="center"
                    >
                      <b-button
                        variant="success"
                        @click="actionsGenre('create')"
                      >
                        <span>{{ $t('createGen') }}</span>
                      </b-button>
                    </b-col>
                  </b-jumbotron>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="genre-view"
      ref="genre-view"
      size="lg"
      hide-footer
      :title="$t('epg.genre')"
      @hidden="resetModal"
    >
      <div class="d-block">
        <b-row class="">
          <b-col class="p-2">
            <h1>{{ $t('editGenre') }}</h1>
            <h5 class="text-primary">
              {{ $t('genreConfg') }}
            </h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-tabs
              v-if="languageService && !createBool"
              pills
              lazy
              vertical
            >
              <b-tab
                v-for="i in locales"
                :key="i.locale"
              >
                <template #title>
                  <div
                    class="d-flex"
                    style="flex:auto"
                  >
                    <b-img
                      :src="i.img"
                      height="14px"
                      width="22px"
                      :alt="i.locale"
                    />
                    <strong class="ml-50">{{ i.name }}</strong>
                  </div>
                </template>
                <genre-view
                  :id="id"
                  :key="i.locale"
                  ref="genreView"
                  :create-bool="createBool"
                  :action="action"
                  :language="i.locale"
                  :base="base"
                  @refresh="getData()"
                />
              </b-tab>
            </b-tabs>
            <genre-view
              v-else
              :id="id"
              ref="genreView"
              :action="action"
              :language="base"
              :base="base"
              :create-bool="createBool"
              @refresh="getData()"
            />
          </b-col>
        </b-row>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="resetModal()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BButton,
  BCardTitle,
  BCard,
  BImg,
  BModal,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
  BJumbotron,
  BListGroupItem,
  BListGroup,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import localesLanguages from '@core/utils/languageModels'
import GenreView from './GenreView.vue'

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardBody,
    BListGroup,
    BJumbotron,
    BTab,
    BCardTitle,
    GenreView,
    BListGroupItem,
    BTabs,
    BRow,
    BCol,
    BModal,
    BButton,
    BCard,
    BImg,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      genre: null,
      languageService: true,
      base: '',
      userData: getUserData(),
      show: false,
      name: '',
      locales: [],
      id: null,
      action: null,
      createBool: false,
      description: '',
      nameState: null,
      active: true,
      headers: {},

    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getClient()
    this.getData()
  },
  methods: {

    getClient() {
      axios
        .post('', {
          query: `
              query{
              client(id:"${this.userData.profile.client.id}"){
                 
                  defaultLanguage
                  availableLanguages
              }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`
          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))

          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')

      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-') // Dividir el idioma en partes usando '-'
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)) // Capitalizar cada parte
          .join('') // Unir las partes sin espacio
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    resetModal() {
      this.$refs['genre-view'].hide()
      this.show = false
      this.id = null
    },

    getData() {
      const { headers } = this

      this.show = true
      const userData = getUserData()
      axios
        .post('', {
          query: `
        {
            allGenre(client:"${userData.profile.client.id}")
            {
            edges
            {
                node
                {
                    id
                    name
                    description
                    isActive
                    client
                    {
                        id
                        name
                    }

                    }
                }
            }
        }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.genre = res.data.data.allGenre.edges
          this.show = false
          this.$nextTick(() => {
            this.$bvModal.hide('genre-view')
          })
        })
        .catch(() => {
          this.show = false
        })
    },
    createGenre() {
      if (!this.checkFormValidity()) {
        return
      }
      const userData = getUserData()
      axios
        .post('', {
          query: `
          mutation{
            createGenre(input:{name:"${this.name}",client:"${userData.profile.client.id}",description:"${this.description}",isActive:${this.active}}){
              genre {
                id
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)

          this.getData()
          this.show = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })

      this.$nextTick(() => {
        this.$bvModal.hide('genre-view')
      })
    },
    editGenre() {
      if (!this.checkFormValidity()) {
        return
      }
      axios
        .post('', {
          query: `
          mutation{
            updateGenre(id:"${this.id}",input:{name:"${this.name}",description:"${this.description}",isActive:${this.active}}){
              genre {
                id
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.getData()
          this.show = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.show = false
        })

      this.$nextTick(() => {
        this.$bvModal.hide('genre-view')
      })
    },
    deleteGenre(id) {
      this.show = true

      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('remGenre'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
        mutation{
            deleteGenre(id:"${id}"){
              found
              deletedId
            }
        }
        `,
            })
            .then(res => {
              messageError(res, this)

              showToast(this.$t('code.updateData'), 1, this)
              this.getData()
              this.show = false
            })
            .catch(() => {
              showToast(this.$t('code.updateDataError'), 2, this)
              this.show = false
            })
        } else {
          this.show = false
        }
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    actionsGenre(value, id = null) {
      this.show = true
      switch (value) {
        case 'update':
          this.createBool = false
          this.id = id

          this.$refs['genre-view'].show()
          break
        case 'create':
          this.createBool = true
          this.id = id

          this.$refs['genre-view'].show()
          break

        default:
          break
      }
    },

  },
}
</script>
<style lang="scss" scoped>
#genre .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#genre .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}
</style>
