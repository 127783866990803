<template>
  <section>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showData"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                {{ $t("notification.title") }} <feather-icon icon="InfoIcon" />
              </b-card-title>
            </b-card-header>
            <b-card-body class="mt-2 mb-2">
              <b-form @submit.prevent="updateNotification()">
                <b-row class="infoContent mb-4">
                  <b-col>Apple Push PEM</b-col>
                  <b-col>
                    <b-form-file
                      v-model="apnKeyValue"
                      accept=".pem"
                      :placeholder="apnKey"
                    />

                    <small class="text-primary">{{
                      $t("notification.applekey")
                    }}</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="danger"
                      @click="deleteFile('apn_key')"
                    >
                      {{ $t('dataGeneric.delete') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>Apple Push Topic </b-col>

                  <b-col>
                    <b-form-input
                      v-model="apnTopic"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">apnTopic</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-clipboard:copy="apnTopic"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>{{ $t("notification.fcmServiceAccountJson") }}</b-col>

                  <b-col>
                    <b-form-textarea
                      v-model="fcmServiceAccountJson"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{
                      $t("notification.fcmServiceAccountJson")
                    }}</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-clipboard:copy="fcmServiceAccountJson"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-button
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormInput,
  BOverlay,
  BFormFile,
  BFormTextarea,
  BForm,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormTextarea,
    BOverlay,
    BFormInput,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      apnKey: null,
      showData: false,
      apnKeyValue: null,
      id: null,
      apnTopic: null,
      deleteType: '',
      fcmServiceAccountJson: null,
    }
  },
  mounted() {
    this.getClient()
  },
  methods: {
    deleteFile(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('shureDel'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.deleteType = type
            this.apnKeyValue = null
            this.apnKey = null
            this.updateNotification()
          }
        })
        .catch(() => { })
    },
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  name
                  apnKey
                  apnTopic
                  fcmServiceAccountJson
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.apnKey = response.node.apnKey
          this.fcmServiceAccountJson = response.node.fcmServiceAccountJson
          this.apnTopic = response.node.apnTopic
          this.deleteType = ''
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateNotification() {
      this.showData = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
            mutation($apnTopic: String, $fcmServiceAccountJson: String){
            updateClient(id:"${this.id}",input:{
              apnTopic: $apnTopic,
              fcmServiceAccountJson: $fcmServiceAccountJson
            }) {              
              client{
                name
                id
              }
            }
          }
        `
      const variables = {
        fcmServiceAccountJson: this.fcmServiceAccountJson,
        apnTopic: this.apnTopic,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      if (this.apnKeyValue != null) {
        data.append('apn_key', this.apnKeyValue)
      }
      if (this.deleteType) {
        data.append('deleteFile', this.deleteType)
      }

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.Not'), 1, this)

          this.showData = false
          this.getClient()
        })
        .catch(() => {
          showToast(this.$t('code.errorConf'), 2, this)
          this.showData = false
        })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.tCopy'),
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.errorCopy'),
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
