<template id="genre-view">
  <b-form
    ref="form"
    @submit.prevent="handleOk"
  >
    <b-row>
      <b-col
        v-if="id == null"
        style="text-align: center;"

        md="10"
      >
        <b-alert show>
          <small>{{ $t('asigCont') }}</small>
        </b-alert>
      </b-col>
      <b-col style="align-self: center; text-align: end;">
        <b-button
          variant="success"
          @click="handleOk"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="9">
        <b-form-group
          :label="$t('firstName') + ' *'"
          label-for="name-input"
          :invalid-feedback="$t('required')"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            :placeholder="$t('dataGeneric.name')"
            maxlength="250"
            required
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="language == base"
        md="3"
        style="align-self: center;"
      >
        <b-form-checkbox
          v-model="isActive"
          switch
        >
          {{ $t('dataGeneric.activeQ') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="language == base">
      <b-col>
        <b-form-group
          :label="$t('dataGeneric.description')"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="description"
            class="mb-1"
            :placeholder="$t('dataGeneric.description')"
            rows="2"
            max-rows="4"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>

import {
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
  BButton,
  BFormGroup,
  BFormTextarea,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BAlert,
    BFormInput,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    id: {
      default: null,
      type: String,
    },
    base: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    createBool: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      badges: null,
      userData: getUserData(),
      name: '',
      nameState: null,
      description: null,
      isActive: true,
      headers: {},
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.id) { this.getData() }
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.id !== null) { this.editGenre() } else this.createGenre()
    },
    getData() {
      const { headers } = this
      axios
        .post('', {
          query: `
            {
              allGenre(id:"${this.id}"){
              edges
                {
                  node
                    {
                        id
                        name(lang:"${this.language}")
                        description
                        isActive
                        }
                    }
                }
            }
            `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          const response = res.data.data.allGenre.edges[0]
          this.name = response.node.name
          this.description = response.node.description
          this.isActive = response.node.isActive
        })
        .catch(() => {
        })
    },
    editGenre() {
      const { headers } = this

      if (!this.checkFormValidity()) {
        return false
      }
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: this.name, type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'description', value: this.description, type: 'String' },
          { title: 'isActive', value: this.isActive, type: 'Boolean' },
        )
      }
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateGenre(id:"${this.id}",input:{\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
                genre {
                   id
                 }
                }
            }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.show = false
        })
      return true
    },
    createGenre() {
      if (!this.checkFormValidity()) {
        return false
      }
      const { headers } = this
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
            mutation{
              createGenre(input:{name:"${this.name}",client:"${userData.profile.client.id}",description:"${this.description}",isActive:${this.isActive}}){
                genre {
                  id
                }
              }
            }
          `
      data.append('query', query)
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })
      return true
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
  },

}
</script>

<style lang="scss" scoped>
#genre-view .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#genre-view .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}

#genre-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#genre-view .card-body h4 {
  font-size: 1.286rem !important;
}

#genre-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#genre-view .fly-image-input {
  max-width: 100%;
}

#genre-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#genre-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#genre-view .div {
  display: inline-block;
}

#genre-view .inputColor {
  visibility: hidden;
}

#genre-view .placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#genre-view .placeholder_photo:hover {
  background: #e0e0e0;
}

#genre-view .file-input {
  display: none;
}

#genre-view .cBox {
  align-items: center;
}

#genre-view .custom-control-label {
  font-size: 15px;
}

#genre-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#genre-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#genre-view .contain {
  object-fit: contain;
  cursor: pointer;
}
</style>
