<template>
  <section id="platform-settings-bucket">
    <!-- Bucket configuration -->
    <b-row class="match-height mt-2">
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Brightcove
              <feather-icon icon="InfoIcon" />
            </b-card-title>
          </b-card-header>

          <b-form @submit.prevent="updateBRU">
            <b-row class="m-1">
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('brigthServer')"
                >
                  <div class="d-flex">
                    <b-form-input
                      v-model="serverBRU"
                      autocomplete="new-password"
                      class="mr-1"
                      maxlength="250"
                    />
                    <b-button
                      v-clipboard:copy="serverBRU"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="m-1">
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  label="Secret Key Brightcove"
                >
                  <div class="d-flex">
                    <b-input-group class="mr-1">
                      <b-form-input
                        v-model="secretkeyBRU"
                        :type="passwordFieldType"
                        autocomplete="new-password"
                        maxlength="250"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <b-button
                      v-clipboard:copy="secretkeyBRU"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="m-1">
              <b-col>
                <b-button
                  type="submit"
                  variant="success"
                >
                  {{
                    $t("dataGeneric.save")
                  }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BCardTitle,
  BCardHeader,
  BFormInput,
  BForm,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BCardTitle,
    BCardHeader,
    BFormInput,
  },

  directives: {
    Ripple,
  },

  mixins: [togglePasswordVisibility],
  data() {
    return {
      secretkeyBRU: null,
      serverBRU: null,
      platform: {
        name: '',
        emailContact: '',
        bucketType: '',
      },
      bucketTypes: [],
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  created() {
    const userData = getUserData()
    this.$http
      .post('', {
        query: `{
        client(id: "${userData.profile.client.id}") {
          id
          name
          emailContact
          bucketType
          brigthcoveServer
          brigthcoveSecretCode
        }
      }`,
      })
      .then(r => {
        this.platform = r.data.data.client
        this.secretkeyBRU = r.data.data.client.brigthcoveSecretCode
        this.serverBRU = r.data.data.client.brigthcoveServer
      })
  },
  methods: {
    updateBRU() {
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const userData = getUserData()

      const query = `
            mutation{
            updateClient(id:"${userData.profile.client.id}",input:{
              ${
  this.serverBRU == null
    ? ''
    : `brigthcoveServer:"${this.serverBRU}",`
}
              ${
  this.secretkeyBRU == null
    ? ''
    : `brigthcoveSecretCode:"${this.secretkeyBRU}",`
}

            }) {
              client{
                name
                id
              }
            }
          }
        `
      data.append('query', query)

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.tCopy'),
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.errorCopy'),
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
#platform-settings-bucket .col-form-label {
  font-size: inherit;
  line-height: 1.45;
}
</style>
