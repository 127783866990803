<template>
  <section id="platform-settings-connection">
    <!-- Platform name -->
    <b-row class="match-height mt-2">
      <b-col>
        <b-card>
          <b-card-header>
            <b-card-title>
              {{ $t("connection.title") }}<feather-icon icon="InfoIcon" />
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col
              lg="11"
              class="ml-2"
            >
              <!-- form input -->
              <b-form-group
                class="mb-2 mr-1"
                label="API URL"
              >
                <div class="d-flex">
                  <b-form-input
                    v-model="apiURL"
                    autocomplete="new-password"
                    class="mr-1"
                    readonly
                  />
                  <!-- button -->
                  <b-button
                    v-clipboard:copy="apiURL"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                  >
                    {{ $t("dataGeneric.copy") }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              lg="11"
              class="ml-2"
            >
              <!-- form input -->
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('connection.platform')"
              >
                <div class="d-flex">
                  <b-form-input
                    v-model="clientID"
                    autocomplete="new-password"
                    class="mr-1"
                    readonly
                  />
                  <!--button-->
                  <b-button
                    v-clipboard:copy="clientID"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                  >
                    {{ $t("dataGeneric.copy") }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="11"
              class="ml-2"
            >
              <!-- form input -->
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('connection.pk')"
              >
                <div class="d-flex">
                  <b-form-input
                    v-model="clientPK"
                    autocomplete="new-password"
                    class="mr-1"
                    readonly
                  />
                  <!--button-->
                  <b-button
                    v-clipboard:copy="clientPK"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                  >
                    {{ $t("dataGeneric.copy") }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="11"
              class="ml-2"
            >
              <!--form input-->
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('connection.token')"
              >
                <div class="d-flex">
                  <b-form-input
                    v-model="demoToken"
                    autocomplete="new-password"
                    class="mr-1"
                    readonly
                  />
                  <!--button-->
                  <b-button
                    v-clipboard:copy="demoToken"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                  >
                    {{ $t("dataGeneric.copy") }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="11"
              class="ml-2"
            >
              <!--form input-->
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('connection.user')"
              >
                <div class="d-flex">
                  <b-form-input
                    v-model="demoUser"
                    autocomplete="new-password"
                    class="mr-1"
                    readonly
                  />
                  <!--button-->
                  <b-button
                    v-clipboard:copy="demoUser"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                  >
                    {{ $t("dataGeneric.copy") }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              lg="11"
              class="ml-2"
            >
              <!-- form input -->
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('connection.pass')"
              >
                <div class="d-flex">
                  <b-form-input
                    v-model="demoPassword"
                    autocomplete="new-password"
                    class="mr-1"
                    readonly
                  />
                  <!-- button -->
                  <b-button
                    v-clipboard:copy="demoPassword"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                  >
                    {{ $t("dataGeneric.copy") }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, b64ToUtf8 } from '@/store/functions'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BFormGroup,
    BFormInput,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      apiURL: '',
      clientID: '',
      clientPK: '',
      demoUser: '',
      demoToken: '',
      demoPassword: '',
    }
  },
  mounted() {
    const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
    if (mode === 'production') {
      this.apiURL = `${window.location.protocol}//api.interactvty.com/api/1.0/`
    } else {
      let currentHost = window.location.host
      if (currentHost.includes('dashboard-')) {
        currentHost = currentHost.replace('dashboard-', '')
      }
      this.apiURL = `${window.location.protocol}//${currentHost}/api/1.0/`
    }

    this.getConexionPlatformData()
  },
  methods: {
    getConexionPlatformData() {
      const userData = getUserData()
      const query = `
        query {
          client(id: "${userData.profile.client.id}"){
            id
            clientId
            demoUsername
            demoPassword
            demoToken
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        this.clientID = res.data.data.client.clientId
        this.demoUser = res.data.data.client.demoUsername
        this.demoPassword = res.data.data.client.demoPassword
        this.demoToken = res.data.data.client.demoToken
        const [, pk] = b64ToUtf8(res.data.data.client.id).split(':')
        this.clientPK = pk
      })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.tCopy'),
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.errorCopy'),
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss"></style>
