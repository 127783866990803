<template>
  <section>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showData"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                {{ $t("paid.title") }} <feather-icon icon="InfoIcon" />
              </b-card-title>
            </b-card-header>
            <b-card-body class="mt-2 mb-2">
              <b-form @submit.prevent="updatePaid($event)">
                <b-row class="infoContent mb-4">
                  <b-col> {{ $t("paid.title") }} </b-col>
                  <b-col>
                    <b-form-select
                      v-model="externalPayMethod"
                      :options="optionsPayMethod"
                    />

                    <small class="text-primary">{{ $t("paid.small") }} </small>
                  </b-col>

                  <b-col />
                </b-row>
                <b-row
                  v-if="externalPayMethod == 'STR'"
                  class="infoContent mb-4"
                >
                  <b-col>{{ $t("paid.stripe") }}</b-col>

                  <b-col>
                    <b-form-input
                      v-model="stripeSecretKey"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("paid.secret") }}</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-clipboard:copy="stripeSecretKey"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row
                  v-if="externalPayMethod != null"
                  class="infoContent mb-4"
                >
                  <b-col v-if="externalPayMethod == 'EPO'">
                    {{ $t("paid.epoch") }}
                  </b-col>
                  <b-col v-else>
                    {{ $t("paid.stripeid") }}
                  </b-col>
                  <b-col>
                    <b-form-input
                      v-model="stripePublishKey"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("paid.publish") }}</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-clipboard:copy="stripePublishKey"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>
                    {{ $t("paid.client") }}
                  </b-col>
                  <b-col>
                    <b-form-input
                      v-model="clientIdPay"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("paid.client") }}</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-clipboard:copy="clientIdPay"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>
                    {{ $t("paid.clientsecret") }}
                  </b-col>
                  <b-col>
                    <b-form-input
                      v-model="clientSecretPay"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("paid.clientsecret") }}</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-clipboard:copy="clientSecretPay"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>{{ $t("paid.google") }}</b-col>
                  <b-col>
                    <b-form-input
                      v-model="clientRefreshTokenPay"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("paid.google") }}</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-clipboard:copy="clientRefreshTokenPay"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col md="4">
                    {{ $t("hasRevenuecat") }}
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      v-model="hasRevenuecat"
                      switch
                    >
                      Revenuecat
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row
                  v-if="hasRevenuecat"
                  class="infoContent mb-4"
                >
                  <b-col>{{ $t("revenuecatApikey") }}</b-col>
                  <b-col>
                    <b-form-input
                      v-model="revenuecatApikey"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("revenuecatApikey") }}</small>
                  </b-col>
                  <b-col>
                    <b-button
                      v-clipboard:copy="revenuecatApikey"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row
                  v-if="hasRevenuecat"
                  class="infoContent mb-4"
                >
                  <b-col md="4">
                    {{ $t("revenuecatStripeIntegration") }}
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      v-model="revenuecatStripeIntegration"
                      switch
                      autocomplete="new-password"
                    >
                      {{ $t("revenuecatStripeIntegrationInfo") }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-button
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
  BForm,
  BFormSelect,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BForm,
    BFormInput,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      externalPayMethod: null,
      stripeSecretKey: null,
      stripePublishKey: null,
      onlyStripe: null,
      showData: false,
      clientIdPay: null,
      clientSecretPay: null,
      clientRefreshTokenPay: null,
      hasRevenuecat: false,
      revenuecatApikey: '',
      revenuecatStripeIntegration: false,
      id: null,
      optionsPayMethod: [
        { value: null, text: this.$t('code.selecciona') },
        { value: 'STR', text: 'Stripe' },
        { value: 'EPO', text: 'Epoch' },
      ],
    }
  },
  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  name
                  externalPayMethod
                  stripeSecretKey
                  stripePublishKey
                  onlyStripe                  
                  clientIdPay
                  clientSecretPay
                  clientRefreshTokenPay
                  revenuecatApikey
                  revenuecatStripeIntegration
                  hasRevenuecat
                  
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]

          this.externalPayMethod = response.node.externalPayMethod
          this.id = response.node.id
          this.stripeSecretKey = response.node.stripeSecretKey
          this.stripePublishKey = response.node.stripePublishKey
          this.clientIdPay = response.node.clientIdPay
          this.clientSecretPay = response.node.clientSecretPay
          this.clientRefreshTokenPay = response.node.clientRefreshTokenPay
          this.hasRevenuecat = response.node.hasRevenuecat
          this.revenuecatStripeIntegration = response.node.revenuecatStripeIntegration
          this.revenuecatApikey = response.node.revenuecatApikey
        })
        .catch(err => {
          console.log(err)
        })
    },
    updatePaid(event) {
      this.showData = true
      event.preventDefault()

      axios
        .post('', {
          query: `
            mutation{
            updateClient(id:"${this.id}",input:{
              ${this.externalPayMethod === null
    ? ''
    : `externalPayMethod:${this.externalPayMethod},`
}
              ${this.stripeSecretKey === ''
    ? ''
    : `stripeSecretKey:"${this.stripeSecretKey}",`
}
              ${this.stripePublishKey === ''
    ? ''
    : `stripePublishKey:"${this.stripePublishKey}",`
}
              ${this.clientIdPay === ''
    ? ''
    : `clientIdPay:"${this.clientIdPay}",`
}
              ${this.clientSecretPay === ''
    ? ''
    : `clientSecretPay:"${this.clientSecretPay}",`
}
              ${this.clientRefreshTokenPay === ''
    ? ''
    : `clientRefreshTokenPay:"${this.clientRefreshTokenPay}",`
}
              hasRevenuecat:${this.hasRevenuecat},
              revenuecatApikey:"${this.revenuecatApikey}",
              revenuecatStripeIntegration:${this.revenuecatStripeIntegration},
              
            }) {              
              client{
                name
                id
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.correctPaid'), 1, this)
          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('code.errorPaid'), 2, this)
          this.showData = false
        })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.tCopy'),
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.errorCopy'),
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
