<template>
  <section id="settingsSeo">
    <b-row class="match-height mt-2">
      <b-col>
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t('Shop') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                lg="12"
                class="ml-2 mb-2"
              >
                <b-overlay
                  variant="transparent"
                  :show="show"
                >
                  <b-form @submit.prevent="updateShop">
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('tipoTienda')"
                    >
                      <b-form-select
                        v-model="typeShop"
                        :disabled="!isShop"
                        :options="[
                          { value: 'MIX', text: $t('Mixta') },
                          { value: 'INT', text: $t('Interna') },
                          { value: 'A2C', text: 'Api2Cart' },
                        ]"
                      />
                    </b-form-group>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('Moneda')"
                    >
                      <b-form-select
                        v-model="currency"
                        :disabled="!isShop"
                        :options="[
                          { value: 'EUR', text: 'Euro' },
                          { value: 'USD', text: 'Dolar' },
                          { value: 'GBP', text: 'Libra' },
                          { value: 'ARS', text: 'Peso argentino' },
                        ]"
                      />
                    </b-form-group>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('Impuestos')"
                    >
                      <b-form-input
                        v-model="tax"
                        :disabled="!isShop"
                        placeholder="Tax"
                        type="number"
                        maxlength="80"
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('apiKey')"
                    >
                      <b-form-input
                        v-model="shopApiKey"
                        :disabled="!isShop"
                        :placeholder="$t('apiKey')"
                        class="mr-1"
                        maxlength="180"
                      />
                    </b-form-group>
                    <div class="d-flex align-items-center">
                      <b-form-group
                        class="mb-2 mr-1"
                        :label="$t('factura')"
                      >
                        <b-form-checkbox
                          v-model="isInvoice"
                          switch
                          :disabled="!isShop"
                          class="mr-1"
                        />
                      </b-form-group>
                      <b-form-group
                        class="mb-2 mr-1"
                        :label="$t('stripe')"
                      >
                        <b-form-checkbox
                          v-model="onlyStripe"
                          switch
                          :disabled="!isShop"
                          class="mr-1"
                        />
                      </b-form-group>
                    </div>

                    <b-button
                      :disabled="!isShop"
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-form>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormInput,
  BOverlay,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'

import { showToast, messageError } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BForm,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormInput,
    BOverlay,
    BFormCheckbox,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isShop: false,
      show: false,
      isInvoice: false,
      onlyStripe: false,
      typeShop: null,
      tax: null,
      id: null,
      currency: null,
      shopApiKey: null,
    }
  },

  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  isShop                     
                  typeShop                   
                  isInvoice
                  onlyStripe
                  tax
                  currency
                  shopApiKey
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.isShop = response.node.isShop
          this.typeShop = response.node.typeShop
          this.isInvoice = response.node.isInvoice
          this.onlyStripe = response.node.onlyStripe
          this.tax = response.node.tax
          this.currency = response.node.currency
          this.shopApiKey = response.node.shopApiKey
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateShop(event) {
      this.show = true
      event.preventDefault()
      const query = `
            mutation{
            updateClient(id:"${this.id}",input:{
              ${this.shopApiKey == null ? '' : `shopApiKey:"${this.shopApiKey}",`}
              currency:${this.currency},
              typeShop:${this.typeShop},
              isInvoice:${this.isInvoice},
              onlyStripe:${this.onlyStripe},
              ${this.tax == null
    ? ''
    : `tax:${this.tax},`
}

            }) {
              client{
                name
                id
              }
            }
          }
        `

      axios.post('', { query })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.show = false
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
