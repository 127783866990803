<template>
  <div id="faq">
    <b-row>
      <b-col>
        <b-card v-if="faqs != null">
          <b-card-header>
            <b-card-title>FAQ</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row
              v-if="faqs.length != 0"
              class="mb-1"
            >
              <b-col>
                <h5 class="text-primary">
                  {{ $t('Faq') }}
                </h5>
              </b-col>
              <b-col style="text-align-last: end;">
                <b-button
                  class="mr-2"
                  @click="actionsFaq('order')"
                >
                  {{ $t('contents.sort') }}
                </b-button>
                <b-button
                  variant="primary"
                  @click="actionsFaq('create')"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="14"
                  /> {{ $t('dataGeneric.create') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col
                v-if="manual"
                class="d-flex align-items-center"
              >
                <b-button
                  variant="outline-warning"
                  disabled
                >
                  {{ $t("contents.manualSortMode") }}
                </b-button>
                <b-button
                  class="ml-2"
                  variant="success"
                  @click.stop.prevent="actionsFaq('order')"
                >
                  {{ $t("contents.finalize") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-overlay
                  :show="show"
                  variant="transparent"
                  rounded="sm"
                >
                  <div v-if="faqs.length != 0">
                    <app-collapse
                      id="faq-payment-qna"
                      accordion
                      type="margin"
                      class="mt-2 list"
                    >
                      <draggable
                        :list="faqs"
                        group="faqs"
                        :sort="true"
                        :handle="!manual ? '.handle' : ''"
                      >
                        <div
                          v-for="data in faqs"
                          :key="data.node.id"
                          class="d-flex justify-content-between"
                          @dragend="updateOrder()"
                        >
                          <div
                            v-if="!manual"
                            class="d-flex align-items-center mr-1"
                          >
                            <span
                              v-if="data.node.isActive"
                              class="mr-50 bullet bullet-success bullet-sm"
                            /><span
                              v-else
                              class="mr-50 bullet bullet-danger bullet-sm"
                            />
                          </div>
                          <app-collapse-item
                            class="item-collapse"
                            :title="data.node.question"
                          >
                            {{ data.node.answer }}
                          </app-collapse-item>
                          <div
                            v-if="!manual"
                            class="config-buttons"
                          >
                            <span
                              class="mr-1 edit"
                              @click="
                                actionsFaq(
                                  'update',
                                  data.node.id,
                                )
                              "
                            >
                              <feather-icon
                                icon="EditIcon"
                                size="16"
                              />
                            </span>
                            <span
                              class="trash"
                              @click="deleteFaq(data.node.id)"
                            >
                              <feather-icon
                                icon="Trash2Icon"
                                size="18"
                              />
                            </span>
                          </div>
                        </div>
                      </draggable>
                    </app-collapse>
                  </div>

                  <b-jumbotron
                    v-else
                    bg-variant="transparent"
                    border-variant="primary"
                    :header="$t('createFaq')"
                    class="text-center"
                  >
                    <p class="text-primary">
                      {{ $t('createFaqs') }}
                    </p>

                    <b-col
                      class="text-center m-10 p-5"
                      align-self="center"
                    >
                      <b-button
                        variant="success"
                        @click="actionsFaq('create')"
                      >
                        <span>{{ $t('createFaq') }}</span>
                      </b-button>
                    </b-col>
                  </b-jumbotron>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="faq-view"
      ref="faq-view"
      size="lg"
      hide-footer
      title="FAQ"
      @hidden="resetModal"
    >
      <div class="d-block">
        <b-tabs
          v-if="languageService && !createBool"
          pills
          lazy
          vertical
        >
          <b-tab
            v-for="i in locales"
            :key="i.locale"
          >
            <template #title>
              <div
                class="d-flex"
                style="flex:auto"
              >
                <b-img
                  :src="i.img"
                  height="14px"
                  width="22px"
                  :alt="i.locale"
                />
                <strong class="ml-50">{{ i.name }}</strong>
              </div>
            </template>
            <faq-view
              :id="id"
              :key="i.locale"
              :order="order"
              :create-bool="createBool"
              :action="action"
              :language="i.locale"
              :base="base"
              @refresh="getData()"
            />
          </b-tab>
        </b-tabs>
        <faq-view
          v-else
          :id="id"
          :action="action"
          :language="base"
          :order="order"
          :base="base"
          :create-bool="createBool"
          @refresh="getData()"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="resetModal()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BJumbotron,
  BCardTitle,
  BCard,
  BTab,
  BTabs,
  BImg,
  BModal,
  BCardHeader,
  BCardBody,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, isEmpty } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import localesLanguages from '@core/utils/languageModels'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import FaqView from './FaqView.vue'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BOverlay,
    BCardHeader,
    AppCollapseItem,
    AppCollapse,
    draggable,
    BCardBody,
    BCardTitle,
    BJumbotron,
    FaqView,
    BTab,
    BTabs,
    BRow,
    BCol,
    BModal,
    BButton,
    BCard,
    BImg,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      faqs: null,
      manual: false,
      show: false,
      languages: [],
      languageService: true,
      userData: getUserData(),
      action: null,
      id: null,
      locales: [],
      createBool: false,
      order: 0,
      deleteFile: [],
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      console.log(error)
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getClient()
    this.getData()
  },
  methods: {
    errorImg(e) {
      e.target.src = fileError
    },
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')

      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-') // Dividir el idioma en partes usando '-'
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)) // Capitalizar cada parte
          .join('') // Unir las partes sin espacio
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    getClient() {
      axios
        .post('', {
          query: `
              query{
              client(id:"${this.userData.profile.client.id}"){
                 
                  defaultLanguage
                  availableLanguages
              }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`
          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))

          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },
    resetModal() {
      this.show = false

      this.$nextTick(() => {
        this.$bvModal.hide('faq-view')
      })
    },
    updateOrder() {
      const order = []
      const id = []
      console.log(this.faqs)

      this.faqs.forEach(element => {
        id.push(element.node.id)
        order.push(element.node.order)
      })
      const result = [0]
      while (result.length !== order.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push(n + 1)
      }
      let query = ` mutation
      {`

      for (let i = 0; i < id.length; i += 1) {
        query += `
        m${i + 1}: updateFaq(id:"${id[i]}",input:{ order: ${result[i]}}){
                    faq{
                      id
                      order
                    }
                  }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
        })
    },
    getData() {
      this.show = true
      const userData = getUserData()
      const { headers } = this
      axios
        .post('', {
          query: `
            {
            allFaqs(client:"${userData.profile.client.id}"){
            edges{
              node{
                    id
                    answer
                    question
                    order
                    isActive
                  }
                }
              }
            }
            `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.faqs = res.data.data.allFaqs.edges
          this.order = isEmpty(this.faqs) ? 0 : Math.max(...this.faqs.map(faq => faq.node.order)) + 1
          this.$nextTick(() => {
            this.$bvModal.hide('faq-view')
          })
          this.show = false
        })
        .catch(() => {
          this.show = false
        })
    },

    deleteFaq(id) {
      this.show = true

      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('infoFaq'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
                mutation{
                    deleteFaq(id:"${id}"){
                        found
                        deletedId
                    }
                }
                `,
            })
            .then(res => {
              messageError(res, this)

              showToast(this.$t('code.updateData'), 1, this)
              this.getData()
            })
            .catch(() => {
              showToast(this.$t('code.updateDataError'), 2, this)
              this.show = false
            })
        } else {
          this.show = false
        }
      })
    },
    actionsFaq(value, id = null) {
      switch (value) {
        case 'update':
          this.show = true

          this.createBool = false
          this.id = id
          this.$refs['faq-view'].show()
          break
        case 'create':
          this.show = true

          this.createBool = true
          this.id = id
          this.$refs['faq-view'].show()
          break
        case 'order':
          this.manual = !this.manual
          break
        default:
          break
      }
    },

  },
}
</script>

<style lang="scss" scoped>
#faq .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#faq .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}

#faq .div {
  display: inline-block;
}

#faq .inputColor {
  visibility: hidden;
}

#faq .cBox {
  align-items: center;
}

#faq .custom-control-label {
  font-size: 15px;
}

#faq .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#faq .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#faq .contain {
  object-fit: contain;
  cursor: pointer;
}

#faq .config-buttons {
  display: flex;
  align-items: center;
  margin-left: 20px;

}

#faq .item-collapse {
  width: -moz-available;
  width: -webkit-fill-available;
}
/*
#faq .manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
} */
</style>
