<template>
  <section>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showData"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                {{ $t("domain.title") }}
                <feather-icon icon="InfoIcon" />
              </b-card-title>
            </b-card-header>
            <b-card-body class="mt-2 mb-2">
              <b-form @submit.prevent="updateData($event)">
                <b-row class="infoContent mb-4">
                  <b-col><label for="domain">{{ $t("domain.title") }} </label></b-col>
                  <b-col>
                    <b-form-input
                      id="domain"
                      v-model="domain"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("domain.smal") }} </small>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>
                    <label
                      for="nameSubscription"
                      @click="showModal"
                    >{{ $t("domain.sus") }} </label>
                  </b-col>

                  <b-col>
                    <b-input-group @click="showModal">
                      <b-form-input
                        id="nameSubscription"
                        v-model="nameSubscription"
                        disabled
                        autocomplete="new-password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="PlusCircleIcon"
                          class="cursor-pointer"
                          @click="showModal"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-primary">{{ $t("domain.user") }}</small>
                  </b-col>
                </b-row>

                <b-button
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('domain.select')"
    >
      <div class="d-block text-center">
        <subscription-selector-modal @data="SelectSubscriptionId" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormInput,
  BOverlay,
  BForm,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

import { getUserData } from '@/auth/utils'
import SubscriptionSelectorModal from '../../../common/SuscriptionSelectorModal.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BForm,
    BCardTitle,
    BCardBody,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BOverlay,
    SubscriptionSelectorModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      domain: null,
      nameSubscription: null,
      domainSubscription: null,
      showData: false,
      id: null,
    }
  },
  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                 domain
                  domainSubscription{
                  name          
                  id
                }
                 
                  
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.domain = response.node.domain
          this.nameSubscription = response.node.domainSubscription?.name
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateData(event) {
      this.showData = true
      event.preventDefault()
      axios
        .post('', {
          query: `
            mutation{
            updateClient(id:"${this.id}",input:{
              ${this.domain == null ? '' : `domain:"${this.domain}",`}
              ${this.domainSubscription == null
    ? ''
    : `domainSubscription:${this.domainSubscription},`
}            
              
            }) {              
              client{
                name
                id
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.domainUp'), 1, this)

          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('code.errorDomain'), 2, this)
          this.showData = false
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    SelectSubscriptionId(data) {
      const [domain, name] = data
      this.domainSubscription = domain
      this.nameSubscription = name
      this.hideModal()
    },
  },
}
</script>
