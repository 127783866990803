<template>
  <section id="config-user-platform">
    <b-row class="match-height mt-2">
      <b-col lg="12">
        <b-overlay
          variant="transparent"
          :show="showLoading"
        >
          <b-card class="p-1">
            <b-card-body>
              <b-card-title>{{ $t("tabUser.title") }}</b-card-title>
              <b-form @submit.prevent="updateLoginConfig">
                <b-row>
                  <b-col cols="12">
                    <!-- IP DE REGISTRO-->
                    <b-form-group>
                      <label for="ip-registro">{{ $t("tabUser.label") }}</label>
                      <b-input-group>
                        <b-form-input
                          id="ip-registro"
                          v-model="ipRegistro"
                          type="text"
                          :placeholder="$t('tabUser.label')"
                          autocomplete="new-password"
                        />
                      </b-input-group>
                    </b-form-group>

                    <!-- API KEY LOGIN EXTERNO -->
                    <b-form-group>
                      <label for="apikey-registro">{{
                        $t("tabUser.api")
                      }}</label>
                      <b-input-group>
                        <b-form-input
                          id="apikey-registro"
                          v-model="keyRegistro"
                          max="50"
                          type="text"
                          :placeholder="$t('tabUser.api')"
                          autocomplete="new-password"
                        />
                      </b-input-group>
                    </b-form-group>

                    <!-- URL login externo -->
                    <b-form-group>
                      <label for="url-login-externo">{{
                        $t("tabUser.url")
                      }}</label>
                      <b-input-group>
                        <b-form-input
                          id="url-login-externo"
                          v-model="urlLoginExt"
                          type="text"
                          :placeholder="$t('tabUser.url')"
                          autocomplete="new-password"
                        />
                      </b-input-group>
                    </b-form-group>

                    <!-- URL CAMBIO PASSWORD-->
                    <b-form-group>
                      <label for="url-cambio-password">{{
                        $t("tabUser.url2")
                      }}</label>
                      <b-input-group>
                        <b-form-input
                          id="url-cambio-password"
                          v-model="urlCambioPassword"
                          type="text"
                          :placeholder="$t('tabUser.url2')"
                          autocomplete="new-password"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group>
                      <label for="state-login-externo">{{
                        $t("tabUser.login")
                      }}</label>
                      <b-form-checkbox
                        id="state-login-externo"
                        v-model="stateLoginExterno"
                        :switch="true"
                      />
                    </b-form-group>
                    <!-- URL CAMBIO PASSWORD INTERNA-->
                    <b-form-group>
                      <label for="url-cambio-password-interno">{{
                        $t("tabUser.change")
                      }}</label>
                      <b-input-group>
                        <b-form-input
                          id="url-cambio-password-interno"
                          v-model="urlCambioPasswordInterno"
                          type="text"
                          :placeholder="$t('tabUser.change')"
                          autocomplete="new-password"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!-- BUTTONS ZONE -->
                  <b-col
                    cols="12"
                    class="mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="success"
                    >
                      {{ $t("dataGeneric.save") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { showToast, messageError } from '@/store/functions'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ipRegistro: null,
      keyRegistro: null,
      urlLoginExt: null,
      urlCambioPassword: null,
      urlCambioPasswordInterno: null,
      stateLoginExterno: false,
      showLoading: false,
    }
  },
  mounted() {
    this.getConfigUserData()
  },
  methods: {
    getConfigUserData() {
      const userData = getUserData()
      const query = `
        query {
          client (id: "${userData.profile.client.id}"){
            allowedIpRegisterUser
            externalLogin
            apikeyExternalLogin
            urlExternalLogin
            urlExternalChangePassword
            changePasswordCallback
          }
        }
      `
      const variables = {
        id: userData.profile.client.id,
      }
      axios.post('', { query, variables }).then(res => {
        messageError(res, this)

        const dataRes = res.data.data.client
        this.ipRegistro = dataRes.allowedIpRegisterUser
        this.keyRegistro = dataRes.apikeyExternalLogin
        this.urlLoginExt = dataRes.urlExternalLogin
        this.urlCambioPassword = dataRes.urlExternalChangePassword
        this.urlCambioPasswordInterno = dataRes.changePasswordCallback
        this.stateLoginExterno = dataRes.externalLogin
      })
    },
    updateLoginConfig(event) {
      this.showLoading = true
      event.preventDefault()
      if (this.ipRegistro.length > 16) {
        showToast(
          this.$t('code.ip'),
          2, this,
        )
        return
      }
      const userData = getUserData()
      const query = `
        mutation($id: ID!, $ipRegister: String, $apiKey: String, $urlExternal: String, $changePass: String, $status: Boolean, $changeInternalPass: String){
          updateClient(id: $id, input: {
            allowedIpRegisterUser: $ipRegister,
            externalLogin: $status,
            apikeyExternalLogin: $apiKey,
            urlExternalLogin: $urlExternal,
            urlExternalChangePassword: $changePass,
            changePasswordCallback: $changeInternalPass
          }){
            client {
              id
              allowedIpRegisterUser
              externalLogin
              apikeyExternalLogin
              urlExternalLogin
              urlExternalChangePassword
              changePasswordCallback
            }
          }
        }
      `
      const variables = {
        id: userData.profile.client.id,
        ipRegister: this.ipRegistro,
        status: this.stateLoginExterno,
        apiKey: this.keyRegistro,
        urlExternal: this.urlLoginExt,
        changeInternalPass: this.urlCambioPasswordInterno,
        changePass: this.urlCambioPassword,
      }
      axios
        .post('', { query, variables })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.modConf'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorConf'), 2, this)
        })
      this.showLoading = false
    },
  },
}
</script>
