<template>
  <b-form @submit.prevent="updateSocial">
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          {{ $t('Redes sociales') }}
        </b-card-title>
        <b-button
          type="submit"
          variant="success"
        >
          {{
            $t("dataGeneric.save")
          }}
        </b-button>
      </b-card-header>
      <b-card-body>
        <b-overlay
          variant="transparent"
          :show="show"
        >
          <b-form-group class="mb-2 mr-1">
            <label for="facebookUrl">
              <feather-icon icon="FacebookIcon" />
              <span class="ml-1">Facebook URL</span>
            </label>
            <b-form-input
              id="facebookUrl"
              v-model="facebookUrl"
              placeholder="Facebook URL"
              maxlength="350"
              class="mr-1"
            />
          </b-form-group>
          <b-form-group class="mb-2 mr-1">
            <label for="twitterUrl">
              <feather-icon icon="TwitterIcon" />
              <span class="ml-1">Twitter URL</span>
            </label>
            <b-form-input
              id="twitterUrl"
              v-model="twitterUrl"
              placeholder="Twitter URL"
              maxlength="350"
              class="mr-1"
            />
          </b-form-group>
          <b-form-group class="mb-2 mr-1">
            <label for="instagramUrl">
              <feather-icon icon="InstagramIcon" />
              <span class="ml-1">Instagram URL</span>
            </label>
            <b-form-input
              id="instagramUrl"
              v-model="instagramUrl"
              placeholder="Instagram URl"
              maxlength="350"
              class="mr-1"
            />
          </b-form-group>
        </b-overlay>
      </b-card-body>
    </b-card>
  </b-form>
</template>
<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BOverlay,
  BForm,
  BCardBody,
  BFormGroup,
} from 'bootstrap-vue'

import { showToast, messageError } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BCardBody,
    BCard,
    BCardHeader,
    BForm,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BOverlay,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      isShop: false,
      show: false,
      isInvoice: false,
      onlyStripe: false,
      typeShop: null,
      tax: null,
      id: null,
      currency: null,
      facebookUrl: null,
      twitterUrl: null,
      instagramUrl: null,
      deliveryApiKey: null,
    }
  },

  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  facebookUrl
                  twitterUrl
                  instagramUrl
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.facebookUrl = response.node.facebookUrl
          this.twitterUrl = response.node.twitterUrl
          this.instagramUrl = response.node.instagramUrl
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateSocial(event) {
      this.show = true
      event.preventDefault()

      const query = `
            mutation($twitterUrl: String, $facebookUrl: String, $instagramUrl: String){
            updateClient(id:"${this.id}",input:{
              twitterUrl: $twitterUrl,
              facebookUrl: $facebookUrl,
              instagramUrl: $instagramUrl,

            }) {
              client{
                name
                id
              }
            }
          }
        `
      const variables = {
        twitterUrl: this.twitterUrl,
        facebookUrl: this.facebookUrl,
        instagramUrl: this.instagramUrl,
      }
      axios.post('', { query, variables })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.show = false
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
  },
}
</script>
<style lang="scss" scoped></style>
