var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"settingsSeo"}},[_c('b-row',{staticClass:"match-height mt-2"},[_c('b-col',[_c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('Shop')))])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"ml-2 mb-2",attrs:{"lg":"12"}},[_c('b-overlay',{attrs:{"variant":"transparent","show":_vm.show}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateShop($event)}}},[_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('tipoTienda')}},[_c('b-form-select',{attrs:{"disabled":!_vm.isShop,"options":[
                        { value: 'MIX', text: _vm.$t('Mixta') },
                        { value: 'INT', text: _vm.$t('Interna') },
                        { value: 'A2C', text: 'Api2Cart' } ]},model:{value:(_vm.typeShop),callback:function ($$v) {_vm.typeShop=$$v},expression:"typeShop"}})],1),_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('Moneda')}},[_c('b-form-select',{attrs:{"disabled":!_vm.isShop,"options":[
                        { value: 'EUR', text: 'Euro' },
                        { value: 'USD', text: 'Dolar' },
                        { value: 'GBP', text: 'Libra' },
                        { value: 'ARS', text: 'Peso argentino' } ]},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1),_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('Impuestos')}},[_c('b-form-input',{staticClass:"mr-1",attrs:{"disabled":!_vm.isShop,"placeholder":"Tax","type":"number","maxlength":"80"},model:{value:(_vm.tax),callback:function ($$v) {_vm.tax=$$v},expression:"tax"}})],1),_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('apiKey')}},[_c('b-form-input',{staticClass:"mr-1",attrs:{"disabled":!_vm.isShop,"placeholder":_vm.$t('apiKey'),"maxlength":"180"},model:{value:(_vm.shopApiKey),callback:function ($$v) {_vm.shopApiKey=$$v},expression:"shopApiKey"}})],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('factura')}},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"switch":"","disabled":!_vm.isShop},model:{value:(_vm.isInvoice),callback:function ($$v) {_vm.isInvoice=$$v},expression:"isInvoice"}})],1),_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('stripe')}},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"switch":"","disabled":!_vm.isShop},model:{value:(_vm.onlyStripe),callback:function ($$v) {_vm.onlyStripe=$$v},expression:"onlyStripe"}})],1)],1),_c('b-button',{attrs:{"disabled":!_vm.isShop,"type":"submit","variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t("dataGeneric.save"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }