<template>
  <section id="platform-settings">
    <!-- Política de privacidad -->
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <div v-if="!show">
        <b-row class="match-height mt-2">
          <b-col lg="6">
            <b-card
              class="p-1"
              no-body
            >
              <b-card-body>
                <b-card-title>{{ $t("textolegal.title") }}</b-card-title>
                <b-card-sub-title>{{ $t("textolegal.subtitle") }}</b-card-sub-title>
              </b-card-body>

              <b-card-body>
                <b-form @submit.prevent="updatePrivacidad">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="url-privacy">{{ $t("textolegal.url") }}</label>
                        <b-input-group>
                          <b-form-input
                            id="url-privacy"
                            type="url"
                            placeholder="URL"
                            autocomplete="new-password"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group class="mt-3">
                        <label for="text-privacy">{{
                          $t("textolegal.title")
                        }}</label>
                        <quill-editor
                          id="editor"
                          v-model="privacyPolicy"
                          :options="quillOptions"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        class="mr-1"
                      >
                        {{ $t("dataGeneric.cancel") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.save") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2"
                        variant="primary"
                        @click="openLegalModal(privacyPolicy)"
                      >
                        {{ $t("dataGeneric.preview") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="6">
            <b-card
              class="p-1"
              no-body
            >
              <b-card-body>
                <b-card-title> {{ $t("textolegal.aviso") }} </b-card-title>
                <b-card-sub-title>{{ $t("textolegal.terminos") }}</b-card-sub-title>
              </b-card-body>

              <b-card-body>
                <b-form @submit.prevent="updateLegal">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="url-privacy">{{ $t("textolegal.url2") }}</label>
                        <b-input-group>
                          <b-form-input
                            id="url-privacy"
                            type="url"
                            placeholder="URL"
                            autocomplete="new-password"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group class="mt-3">
                        <label for="text-privacy">{{
                          $t("textolegal.aviso2")
                        }}</label>
                        <quill-editor
                          v-model="legalWarning"
                          :options="quillOptions"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        class="mr-1"
                      >
                        {{ $t("dataGeneric.cancel") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.save") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2"
                        variant="primary"
                        @click="openLegalModal(legalWarning)"
                      >
                        {{ $t("dataGeneric.preview") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <!-- Términos y condiciones -->
        <b-row class="match-height mt-2">
          <b-col lg="6">
            <b-card
              class="p-1"
              no-body
            >
              <b-card-body>
                <b-card-title>{{ $t("textolegal.banner1") }}</b-card-title>
                <b-card-sub-title>{{ $t("textolegal.banner2") }}</b-card-sub-title>
              </b-card-body>

              <b-card-body>
                <b-form @submit.prevent="updateBannerCookies">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="url-privacy">{{ $t("textolegal.url3") }}</label>
                        <b-input-group>
                          <b-form-input
                            id="url-privacy"
                            type="url"
                            placeholder="URL"
                            autocomplete="new-password"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group class="mt-3">
                        <label for="text-privacy">{{
                          $t("textolegal.terminos2")
                        }}</label>
                        <quill-editor
                          v-model="cookiesBanner"
                          :options="quillOptions"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        class="mr-1"
                      >
                        {{ $t("dataGeneric.cancel") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.save") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2"
                        variant="primary"
                        @click="openLegalModal(cookiesBanner)"
                      >
                        {{ $t("dataGeneric.preview") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col lg="6">
            <b-card
              class="p-1"
              no-body
            >
              <b-card-body>
                <b-card-title>{{ $t("textolegal.cookies") }}</b-card-title>
                <b-card-sub-title>{{ $t("textolegal.cookies2") }}</b-card-sub-title>
              </b-card-body>

              <b-card-body>
                <b-form @submit.prevent="updatePoliticaCookies">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="url-privacy">{{
                          $t("textolegal.cookies3")
                        }}</label>
                        <b-input-group>
                          <b-form-input
                            id="url-privacy"
                            type="url"
                            placeholder="URL"
                            autocomplete="new-password"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group class="mt-3">
                        <label for="text-privacy">{{
                          $t("textolegal.cookies")
                        }}</label>
                        <quill-editor
                          v-model="cookiesPolicy"
                          :options="quillOptions"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        class="mr-1"
                      >
                        {{ $t("dataGeneric.cancel") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.save") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2"
                        variant="primary"
                        @click="openLegalModal(cookiesPolicy)"
                      >
                        {{ $t("dataGeneric.preview") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <!-- Cláusula para pie de correos y Compliance -->
        <b-row class="match-height mt-2">
          <b-col lg="6">
            <b-card
              class="p-1"
              no-body
            >
              <b-card-body>
                <b-card-title>{{ $t('Pie de correos') }}</b-card-title>
                <b-card-sub-title>{{ $t('clausula') }}</b-card-sub-title>
              </b-card-body>
              <b-card-body>
                <b-form @submit.prevent="updateMailFootClause">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="text-privacy">{{ $t('clausula') }}</label>
                        <quill-editor
                          v-model="mailFootClause"
                          :options="quillOptions"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        class="mr-1"
                      >
                        {{ $t("dataGeneric.cancel") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.save") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2"
                        variant="primary"
                        @click="openLegalModal(mailFootClause)"
                      >
                        {{ $t("dataGeneric.preview") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col lg="6">
            <b-card
              class="p-1"
              no-body
            >
              <b-card-body>
                <b-card-title>Compliance</b-card-title>
                <b-card-sub-title>Compliance</b-card-sub-title>
              </b-card-body>

              <b-card-body>
                <b-form @submit.prevent="updateCompliance">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="text-privacy">Compliance</label>
                        <quill-editor
                          v-model="compliance"
                          :options="quillOptions"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        class="mr-1"
                      >
                        {{ $t("dataGeneric.cancel") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.save") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2"
                        variant="primary"
                        @click="openLegalModal(compliance)"
                      >
                        {{ $t("dataGeneric.preview") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
    <b-modal
      ref="legal-modal"
      hide-footer
      :title="$t('code.prev')"
    >
      <html-modal :msg="modalBody" />
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BOverlay,
  BCardSubTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

import HtmlModal from '@/views/common/HtmlModal.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Quill from 'quill'
import { getUserData } from '@/auth/utils'

const alignStyle = Quill.import('attributors/style/align')
const backgroundStyle = Quill.import('attributors/style/background')
const colorStyle = Quill.import('attributors/style/color')
const directionStyle = Quill.import('attributors/style/direction')
const fontStyle = Quill.import('attributors/style/font')
const sizeStyle = Quill.import('attributors/style/size')

Quill.register(alignStyle, true)
Quill.register(backgroundStyle, true)
Quill.register(colorStyle, true)
Quill.register(directionStyle, true)
Quill.register(fontStyle, true)
Quill.register(sizeStyle, true)
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BModal,
    BButton,
    quillEditor,
    HtmlModal,
  },
  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {

      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            // ['blockquote', 'code-block'],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ direction: 'rtl' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
        },
        theme: 'snow',
      },
      privacyPolicy: null,
      legalWarning: null,
      cookiesBanner: null,
      cookiesPolicy: null,
      userData: getUserData(),
      show: false,
      modalBody: null,
      mailFootClause: null,
      compliance: null,
    }
  },
  mounted() {
    this.getGDPR()
  },
  methods: {
    getGDPR() {
      this.show = true
      const query = `
        query {
          client (id: "${this.userData.profile.client.id}"){
            legalWarning(lang:"${this.language}")
            privacyPolicy(lang:"${this.language}")
            cookiesPolicy(lang:"${this.language}")
            cookiesBanner(lang:"${this.language}")
            mailFootClause(lang:"${this.language}")
            compliance(lang:"${this.language}")
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        const dataRes = res.data.data.client
        this.cookiesBanner = dataRes.cookiesBanner
        this.cookiesPolicy = dataRes.cookiesPolicy
        this.mailFootClause = dataRes.mailFootClause
        this.compliance = dataRes.compliance
        this.privacyPolicy = dataRes.privacyPolicy
        this.legalWarning = dataRes.legalWarning

        this.show = false
      })
    },
    updatePrivacidad(event) {
      event.preventDefault()
      let objectVariables = []
      objectVariables = [
        { title: `privacyPolicy${this.language}`, value: this.convertIndentToStyle(this.privacyPolicy), type: 'String' },
      ]
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateClient(id:"${this.userData.profile.client.id}",input:{\n
  `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        client {
              id
              privacyPolicy
            }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.legalMod'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorLegal'), 2, this)
        })
    },
    updateLegal(event) {
      event.preventDefault()
      let objectVariables = []
      objectVariables = [
        { title: `legalWarning${this.language}`, value: this.convertIndentToStyle(this.legalWarning), type: 'String' },
      ]
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateClient(id:"${this.userData.profile.client.id}",input:{\n
  `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        client {
              id
              legalWarning
            }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.legalMod'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorLegal'), 2, this)
        })
    },
    convertIndentToStyle(text) {
      const regex = /class="ql-indent-(\d)"([^>]*)>/g

      // Reemplazar todas las clases ql-indent-* por el estilo de padding correspondiente
      const newText = text.replace(regex, (match, p1, p2) => {
        // Obtener el número de indentación
        const indentLevel = parseInt(p1, 10)

        // Obtener el estilo existente si lo hay
        let existingStyle = ''
        if (p2.includes('style="')) {
          const [, st] = p2.match(/style="([^"]*)"/)
          existingStyle = st
        }

        // Calcular el valor del padding-left
        const paddingLeft = `${indentLevel * 3}em`

        // Construir el nuevo estilo con el padding-left añadido
        const newStyle = `${existingStyle} padding-left: ${paddingLeft};`

        // Retornar el elemento con el nuevo estilo
        return `class="ql-indent-${indentLevel}" style="${newStyle}">`
      })

      return newText
    },
    updateBannerCookies(event) {
      event.preventDefault()
      let objectVariables = []
      objectVariables = [
        { title: `cookiesBanner${this.language}`, value: this.convertIndentToStyle(this.cookiesBanner), type: 'String' },
      ]
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateClient(id:"${this.userData.profile.client.id}",input:{\n
  `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        client {
              id
              cookiesBanner
            }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.legalMod'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorLegal'), 2, this)
        })
    },
    updatePoliticaCookies(event) {
      event.preventDefault()
      let objectVariables = []
      objectVariables = [
        { title: `cookiesPolicy${this.language}`, value: this.convertIndentToStyle(this.cookiesPolicy), type: 'String' },
      ]
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateClient(id:"${this.userData.profile.client.id}",input:{\n
  `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        client {
              id
              cookiesPolicy
            }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.legalMod'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorLegal'), 2, this)
        })
    },
    updateCompliance(event) {
      event.preventDefault()
      let objectVariables = []
      objectVariables = [
        { title: `compliance${this.language}`, value: this.convertIndentToStyle(this.compliance), type: 'String' },
      ]
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateClient(id:"${this.userData.profile.client.id}",input:{\n
  `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        client {
              id
              compliance
            }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.legalMod'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorLegal'), 2, this)
        })
    },
    updateMailFootClause(event) {
      event.preventDefault()
      let objectVariables = []
      objectVariables = [
        { title: `mailFootClause${this.language}`, value: this.convertIndentToStyle(this.mailFootClause), type: 'String' },
      ]
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateClient(id:"${this.userData.profile.client.id}",input:{\n
  `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        client {
              id
              mailFootClause
            }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.legalMod'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.errorLegal'), 2, this)
        })
    },
    openLegalModal(value) {
      this.modalBody = value
      this.$refs['legal-modal'].show()
    },
    hideModal() {
      this.$refs['legal-modal'].hide()
    },
  },
}
</script>
<style lang="scss" scope>
@import "@core/scss/vue/libs/quill.scss";

#platform-settings .card-body {
  -ms-flex: none;
  flex: none;
}

.ql-container {
  overflow: hidden;
  // overflow-y: scroll;
  height: 400px;
}
</style>
